import * as React from 'react';
import * as MantineCore from '@mantine/core';
import * as HelperUtil from '../lib/helper';
import SearchControl from './SearchControl';
interface Props {
  onChange: (months: number[]) => void;
}
type Option = {
  enabled: boolean;
  label: string;
  value: number;
};
const TimeOfYearInput = ({
  onChange
}: Props) => {
  const [monthOptions, setMonthOptions] = React.useState<Option[]>(HelperUtil.monthNames.map((month, key) => ({
    label: month,
    enabled: false,
    value: key + 1
  })));
  const selectedMonths = monthOptions.filter(month => month.enabled);
  React.useEffect(() => {
    if (selectedMonths.length === 0) {
      // raise 'all' as default
      onChange([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    } else {
      onChange(selectedMonths.map(option => option.value));
    }
  }, [monthOptions]);
  const toggle = (toggleKey: number) => {
    const newMonthOptions = monthOptions.map((monthOption, monthKey) => monthKey === toggleKey ? {
      ...monthOption,
      enabled: !monthOption.enabled
    } : {
      ...monthOption
    });
    setMonthOptions(newMonthOptions);
  };

  // const reset = () => {
  // 	const newMonthOptions = monthOptions.map((monthOption) => ({
  // 		...monthOption,
  // 		enabled: false,
  // 	}))
  // 	setMonthOptions(newMonthOptions)
  // }

  return <SearchControl label={<>When to go</>} control={<div id="time-of-year-input">
					<MantineCore.Button.Group id="when-to-go-group">
						{monthOptions.map((month, key) => <MantineCore.Button key={key} size="xs" radius="sm" color={month.enabled ? 'yellow' : 'gray'} variant={month.enabled ? 'light' : 'default'} style={{
        ...{
          color: month.enabled ? 'brown' : undefined
        }
      }} onClick={() => toggle(key)}>
								{month.label}
							</MantineCore.Button>)}
					</MantineCore.Button.Group>
					{/* {selectedMonths.length > 0 && (
      <MantineCore.Button
      	color="red"
      	variant="outline"
      	size="xs"
      	id="reset-when-to-go"
      	onClick={reset}
      >
      	reset
      </MantineCore.Button>
      )} */}
				</div>} data-sentry-element="SearchControl" data-sentry-component="TimeOfYearInput" data-sentry-source-file="TimeOfYearInput.tsx" />;
};
export default TimeOfYearInput;