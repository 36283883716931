import React from 'react';
import * as MantineCore from '@mantine/core';
import * as Icons from 'tabler-icons-react';
import * as Redux from 'react-redux';
import { useIsMobile } from '../lib/useIsMobile';
import * as HelperUtil from '../lib/helper';
import * as Selectors from '../redux/selectors';
import * as Types from '../declarations';
import CountryFilterInput from './CountryFilterInput';
import ContinentFilterInput from './ContinentFilterInput';
import TimeOfYearInput from './TimeOfYearInput';
import BudgetInput from './BudgetInput';
import RadioButtonGroup from './RadioButtonGroup';
import MultiSelectButtonGroup from './MultiSelectButtonGroup';
import SearchControl from './SearchControl';
import { getVersion } from '../lib/withVersion';
interface Props {
  currentSearchFilter: Types.SearchFilter;
  mapMode: boolean;
  treks: Types.App.HomePageTrip[];
  onChange: (searchFilter: Types.SearchFilter) => void;
  setMapMode: (mapMode: boolean) => void;
}
const SearchFilter = ({
  mapMode,
  currentSearchFilter,
  treks,
  onChange,
  setMapMode
}: Props) => {
  const version = getVersion();
  const [searchValue, setSearchValue] = React.useState(currentSearchFilter.text);
  const [timeOfYear, setTimeOfYear] = React.useState(currentSearchFilter.timeOfYear);
  const [countryFilters, setCountryFilters] = React.useState<string[]>(currentSearchFilter.countryFilters);
  const [continentFilters, setContinentFilters] = React.useState<string[]>(currentSearchFilter.continentFilters);
  const [mapBounds, setMapBounds] = React.useState<Types.MapBounds | undefined>(currentSearchFilter.mapBounds);
  const [duration, setDuration] = React.useState<string | undefined>(undefined);
  const [heightFilter, setHeightFilter] = React.useState<number | undefined>(undefined);
  const [routeTypeFilter] = React.useState<'TREK' | 'CLIMB' | undefined>(undefined);
  const [isWithoutPermit, setIsWithoutPermit] = React.useState<boolean>(false);
  const mostExpensiveTrip: number | undefined = (() => {
    const tempMostExpensiveTrip = (treks ?? []).reduce((maxItem: Types.App.HomePageTrip | undefined, currentItem: Types.App.HomePageTrip) => {
      if (currentItem?.lowestCost && +currentItem.lowestCost > (maxItem?.lowestCost ? +maxItem?.lowestCost : 0)) {
        return currentItem;
      } else {
        return maxItem;
      }
    }, undefined)?.lowestCost;
    return tempMostExpensiveTrip !== undefined ? +tempMostExpensiveTrip : undefined;
  })();
  const [budgetFilter, setBudgetFilter] = React.useState<number | undefined>(mostExpensiveTrip);
  const [simpleDifficultyFilter, setSimpleDifficultyFilter] = React.useState<number[] | undefined>(undefined);
  const [trekkingDifficultyFilter, setTrekkingDifficultyFilter] = React.useState<number[] | undefined>(undefined);
  const isMobile = useIsMobile();
  React.useEffect(() => {
    // override local state values if need be
    setMapBounds(currentSearchFilter.mapBounds);
  }, [currentSearchFilter.mapBounds]);
  React.useEffect(() => {
    const raiseOnChangeEvent = {
      // ...currentSearchFilter, // for things outwith this component (eg mapBounds)
      text: searchValue,
      timeOfYear,
      countryFilters,
      continentFilters,
      mapBounds,
      duration,
      heightFilter,
      isWithoutPermit,
      routeTypeFilter,
      budgetFilter: budgetFilter === mostExpensiveTrip ? undefined : budgetFilter,
      simpleDifficultyFilter,
      trekkingDifficultyFilter
    };
    onChange(raiseOnChangeEvent);
  }, [searchValue, timeOfYear, countryFilters, continentFilters, mapBounds, duration, heightFilter, isWithoutPermit, routeTypeFilter, budgetFilter, simpleDifficultyFilter, trekkingDifficultyFilter]);
  const durationOptions = [{
    display: '1 wk',
    value: '1 wk'
  }, {
    display: '2 wk',
    value: '2 wk'
  }, {
    display: '3 wk',
    value: '3 wk'
  }, {
    display: '4 wk',
    value: '4 wk'
  }, {
    display: '4+ wk',
    value: '4+ wk'
  }];
  const unit = Redux.useSelector(Selectors.unitSelector);
  const resultModeOptions = [{
    display: <>
					<Icons.MapSearch size={18} />
					&nbsp;map&nbsp;
				</>,
    value: true
  }, {
    display: <>
					<Icons.LayoutGrid size={18} />
					&nbsp;results
				</>,
    value: false
  }];
  const metricHeightOptions = [{
    display: '6000',
    value: 6000
  }, {
    display: '6500',
    value: 6500
  }, {
    display: '7000',
    value: 7000
  }, {
    display: '7500',
    value: 7500
  }, {
    display: '8000',
    value: 8000
  }];
  const heightOptions = (unit === 'metric' ? metricHeightOptions : metricHeightOptions.map(({
    value
  }) => ({
    display: HelperUtil.convertMetricToImperial(value),
    value
  }))).map(option => ({
    ...option,
    display: option.display + (unit === 'metric' ? ' m' : ' ft')
  }));
  const isTrekking = version === Types.VERSION.TREKKING;
  return <>
			<div id="search-form">
				<div id="search-filter-section">
					<div className="search-filter-line">
						<MantineCore.Grid data-sentry-element="unknown" data-sentry-source-file="SearchFilter.tsx">
							<MantineCore.Grid.Col md={5} sm={6} xs={12} data-sentry-element="unknown" data-sentry-source-file="SearchFilter.tsx">
								<MantineCore.TextInput size={'md'} placeholder={`filter ${version === Types.VERSION.TREKKING ? 'treks' : 'mountains'} by name...`} onChange={e => setSearchValue(e.target.value)} data-sentry-element="unknown" data-sentry-source-file="SearchFilter.tsx" />
							</MantineCore.Grid.Col>
							{/* <MantineCore.Grid.Col md={3} sm={4} xs={12}>
        <ContinentFilterInput
        	treks={treks}
        	onChange={setContinentFilters}
        />
        </MantineCore.Grid.Col> */}

							<MantineCore.Grid.Col md={4} sm={6} xs={12} data-sentry-element="unknown" data-sentry-source-file="SearchFilter.tsx">
								<CountryFilterInput treks={treks} onChange={setCountryFilters} data-sentry-element="CountryFilterInput" data-sentry-source-file="SearchFilter.tsx" />
							</MantineCore.Grid.Col>

							{/* <div className="hide-medium"> */}
							<MantineCore.Grid.Col md={3} lg={3} className="hide-map-breakpoint" data-sentry-element="unknown" data-sentry-source-file="SearchFilter.tsx">
								<SearchControl
              // label={<></>}
              control={<RadioButtonGroup<boolean> items={resultModeOptions} value={mapMode} size="md" onChange={val => {
                setMapMode(val as boolean);
                if (!val) {
                  setMapBounds(undefined);
                }
              }} nullable={false} />} data-sentry-element="SearchControl" data-sentry-source-file="SearchFilter.tsx" />
							</MantineCore.Grid.Col>
							{/* </div> */}
						</MantineCore.Grid>
					</div>

					{!isMobile && <>
							{isTrekking && <>
									{/* <div className="search-filter-line"> */}
									<MantineCore.Grid>
										<MantineCore.Grid.Col xs={12} sm={10} md={10}>
											<TimeOfYearInput onChange={setTimeOfYear} />
										</MantineCore.Grid.Col>

										<MantineCore.Grid.Col xs={3} sm={2} md={2}>
											<SearchControl label={<>
														<Icons.License size={MantineCore.rem(14)} />
														Permits
													</>} control={<>
														<div style={{
                    verticalAlign: 'middle',
                    display: 'inline-block'
                  }}>
															<MantineCore.Checkbox label="Not needed" checked={isWithoutPermit} onChange={e => setIsWithoutPermit(e.target.checked)} color="yellow" size="md" />
														</div>
													</>} />
										</MantineCore.Grid.Col>
										{/* </MantineCore.Grid>
           </div>
           <div className="search-filter-line">
           <MantineCore.Grid> */}
										<MantineCore.Grid.Col xs={9} sm={5} md={4}>
											<SearchControl label={<>
														<Icons.PhotoPlus size={MantineCore.rem(14)} />
														Duration
													</>} control={<RadioButtonGroup<string> items={durationOptions} value={duration} onChange={setDuration} size="xs" />} />
										</MantineCore.Grid.Col>

										<MantineCore.Grid.Col xs={6} sm={4} md={4}>
											<SearchControl label={<>
														<Icons.Stretching size={MantineCore.rem(14)} />
														Difficulty
													</>} control={<MultiSelectButtonGroup items={[{
                  display: 'Easy',
                  value: 0
                }, {
                  display: 'Moderate',
                  value: 1
                }, {
                  display: 'Challenging',
                  value: 2
                }]} onChange={ev => {
                  setTrekkingDifficultyFilter(ev as number[]);
                }} size="xs" />} />
										</MantineCore.Grid.Col>

										<MantineCore.Grid.Col xs={4} sm={3} md={4}>
											{!!mostExpensiveTrip && <SearchControl label={<>
															<Icons.CashBanknote size={MantineCore.rem(14)} />
															Budget
														</>} control={<BudgetInput value={budgetFilter} upperValue={mostExpensiveTrip} onChange={setBudgetFilter} />} />}
										</MantineCore.Grid.Col>
									</MantineCore.Grid>
									{/* </div> */}
								</>}

							{!isTrekking && <>
									{/* <div className="search-filter-line"> */}
									<MantineCore.Grid>
										<MantineCore.Grid.Col xs={12} sm={10} md={7}>
											<TimeOfYearInput onChange={setTimeOfYear} />
										</MantineCore.Grid.Col>

										<MantineCore.Grid.Col xs={3} sm={2} md={2}>
											<SearchControl label={<>
														<Icons.License size={MantineCore.rem(14)} />
														Permits
													</>} control={<>
														<div style={{
                    verticalAlign: 'middle',
                    display: 'inline-block'
                  }}>
															<MantineCore.Checkbox label="Not needed" checked={isWithoutPermit} onChange={e => setIsWithoutPermit(e.target.checked)} color="yellow" size="md" />
														</div>
													</>} />
										</MantineCore.Grid.Col>
										{/* </MantineCore.Grid>
           </div>
           <div className="search-filter-line">
           <MantineCore.Grid> */}

										<MantineCore.Grid.Col xs={4} sm={6} md={3}>
											{!!mostExpensiveTrip && <SearchControl label={<>
															<Icons.CashBanknote size={MantineCore.rem(14)} />
															Budget
														</>} control={<BudgetInput value={budgetFilter} upperValue={mostExpensiveTrip} onChange={setBudgetFilter} />} />}
										</MantineCore.Grid.Col>

										{version === Types.VERSION.PEAKS && <MantineCore.Grid.Col xs={8} sm={6} md={5}>
												<SearchControl label={<>
															<Icons.Mountain size={MantineCore.rem(14)} />
															Higher than
														</>} control={<RadioButtonGroup<number> items={heightOptions} value={heightFilter} onChange={setHeightFilter} size="xs" />} />
											</MantineCore.Grid.Col>}

										<MantineCore.Grid.Col xs={9} sm={6} md={4}>
											<SearchControl label={<>
														<Icons.PhotoPlus size={MantineCore.rem(14)} />
														Duration
													</>} control={<RadioButtonGroup<string> items={durationOptions} value={duration} onChange={setDuration} size="xs" />} />
										</MantineCore.Grid.Col>

										<MantineCore.Grid.Col sm={6} md={3}>
											<SearchControl label={<>
														<Icons.Stretching size={MantineCore.rem(14)} />
														Difficulty
													</>} control={<MultiSelectButtonGroup<number> items={[{
                  display: 'F',
                  value: 1
                }, {
                  display: 'PD',
                  value: 2
                }, {
                  display: 'AD',
                  value: 3
                }, {
                  display: 'D',
                  value: 4
                }, {
                  display: 'TD',
                  value: 5
                }, {
                  display: 'ED',
                  value: 6
                }
                // {
                // 	display: 'ABO',
                // 	value: 7,
                // },
                ]} onChange={ev => {
                  setSimpleDifficultyFilter(ev as number[]);
                }} size="xs" />} />
										</MantineCore.Grid.Col>
									</MantineCore.Grid>
									{/* </div> */}
								</>}
						</>}
				</div>
			</div>
		</>;
};
export default SearchFilter;