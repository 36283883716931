import React, { ReactNode } from 'react';
interface Props {
  label?: ReactNode;
  control: ReactNode;
}
const SearchControl = ({
  label,
  control
}: Props) => {
  return <div className="search-control" data-sentry-component="SearchControl" data-sentry-source-file="SearchControl.tsx">
			{!!label && <div className="label">{label}</div>}
			{control}
		</div>;
};
export default SearchControl;